// import React, { useState, useEffect } from "react";
// import Navbar from "../Navbar/Navbar";
// import { IoIosArrowRoundForward } from "react-icons/io";
// import Blob from "../../assets/blob.svg";
// import { motion } from "framer-motion";
// import axios from "axios";
// import { Landing, landingcreate } from "../../../../utils/constant";
// import { useNavigate } from "react-router-dom";


// // Helper function to split text into letters
// const AnimatedText = ({ text }) => {
//   const letters = Array.from(text);
//   const [visibleLetters, setVisibleLetters] = useState(0); // Track visible letters

//   useEffect(() => {
//     if (visibleLetters < letters.length) {
//       const timeout = setTimeout(() => {
//         setVisibleLetters((prev) => prev + 1); // Increment visible letters one by one
//       }, 30); // Adjusted typing speed for smoother animation

//       return () => clearTimeout(timeout); // Cleanup the timeout
//     }
//   }, [visibleLetters, letters.length]);







//   return (
//     <div className="inline-block">
//       {letters.slice(0, visibleLetters).map((letter, index) => (
//         <motion.span
//           key={index}
//           initial={{ opacity: 0, y: "100%" }} // Corrected from y: 100% to y: "100%"
//           animate={{ opacity: 1, y: 0 }}
//           transition={{
//             type: "spring",
//             damping: 12,
//             stiffness: 100,
//             delay: index * 0.02, // Reduced delay for faster appearance
//           }}
//         >
//           {letter}
//         </motion.span>
//       ))}
//     </div>
//   );
// };

// // FadeUp animation function
// export const FadeUp = (delay) => {
//   return {
//     initial: { opacity: 0, y: 50 },
//     animate: {
//       opacity: 1,
//       y: 0,
//       transition: {
//         type: "spring",
//         stiffness: 100,
//         duration: 0.5,
//         delay: delay,
//         ease: "easeInOut",
//       },
//     },
//   };
// };

// const Hero = () => {

//   const initialFormData = {
//     name: "",
//     email: "",
//     phone: "",
//     // countryCode: "",
//     country: "",
//     course: "", // Default course
//   };

//   const [isLoading, setIsLoading] = useState(false);
//   const [message, setMessage] = useState("");
//   const [messageType, setMessageType] = useState("");

//   const [formData, setFormData] = useState(initialFormData);
//   const [errors, setErrors] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     // countryCode: "",
//     country: "",
//     course: "",
//     serverError: "",
//   });

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: "",
//       serverError: "",
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formValid = validateForm();
//     if (formValid) {
//       setIsLoading(true);
//       setMessage("");

//       try {
//         // Store Data
//         const storeDataResponse = await axios.post(landingcreate, formData, {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });

//         if (storeDataResponse.status === 200 || storeDataResponse.status === 201) {
//           // Send Email
//           const sendEmailResponse = await axios.post(
//             Landing,
//             {
//               ...formData,
//               message: "Thank you for enrolling!",
//             },
//             {
//               headers: {
//                 "Content-Type": "application/json",
//               },
//             }
//           );

//           setMessageType("success");
//           setMessage("Form submitted successfully!");
//           setFormData(initialFormData); // Reset form after successful submission
//         } else {
//           setErrors((prevErrors) => ({
//             ...prevErrors,
//             serverError: storeDataResponse.data.message || "Failed to store data",
//           }));
//           setMessageType("error");
//           setMessage("Failed to submit the form. Please try again.");
//         }
//       } catch (error) {
//         console.error("Error:", error);
//         const errorMsg =
//           error.response?.data?.message || "An unexpected error occurred";
//         setErrors((prevErrors) => ({
//           ...prevErrors,
//           serverError: errorMsg,
//         }));
//         setMessageType("error");
//         setMessage("Error submitting form, please try again.");
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   const validateForm = () => {
//     const { name, email, phone, country, course } = formData;
//     const newErrors = {};

//     if (!name.trim()) newErrors.name = "Please enter your name";
//     if (!email.trim()) {
//       newErrors.email = "Please enter your email";
//     } else if (
//       !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim())
//     ) {
//       newErrors.email = "Please enter a valid email address";
//     }
//     // if (!countryCode.trim()) newErrors.countryCode = "Please select your country code";
//     if (!phone.trim()) {
//       newErrors.phone = "Please enter your phone number";
//     } else if (!/^\d{7,15}$/.test(phone.trim())) {
//       newErrors.phone = "Please enter a valid phone number";
//     }
//     if (!country.trim()) newErrors.country = "Please select your country";
//     if (!course.trim()) newErrors.course = "Please select a course";

//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };




  

//   const headings = [
//     "Get up to 90% off",
//     "Unlock Your Future with Africa's Biggest Scholarship Program!",
//     "Transform Your Dreams into Reality with Up to-Top-Quality Education!",
// ];


//   const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0); // Track current heading
//   const [nextHeadingIndex, setNextHeadingIndex] = useState(1); // Track next heading

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setNextHeadingIndex((prevIndex) => (prevIndex + 1) % headings.length); // Calculate next heading
//       setCurrentHeadingIndex((prevIndex) => (prevIndex + 1) % headings.length); // Update current heading
//     }, 6000); // Change heading every 4 seconds

//     return () => clearInterval(interval); // Cleanup on unmount
//   }, [headings.length]);


//   const countries = [
//     { name: "Algeria", code: "+213" },
//     { name: "Angola", code: "+244" },
//     { name: "Benin", code: "+229" },
//     { name: "Botswana", code: "+267" },
//     { name: "Burkina Faso", code: "+226" },
//     { name: "Burundi", code: "+257" },
//     { name: "Cape Verde", code: "+238" },
//     { name: "Cameroon", code: "+237" },
//     { name: "Central African Republic", code: "+236" },
//     { name: "Chad", code: "+235" },
//     { name: "Comoros", code: "+269" },
//     { name: "Democratic Republic of the Congo", code: "+243" },
//     { name: "Djibouti", code: "+253" },
//     { name: "Egypt", code: "+20" },
//     { name: "Equatorial Guinea", code: "+240" },
//     { name: "Eritrea", code: "+291" },
//     { name: "Eswatini", code: "+268" },
//     { name: "Ethiopia", code: "+251" },
//     { name: "Gabon", code: "+241" },
//     { name: "Gambia", code: "+220" },
//     { name: "Ghana", code: "+233" },
//     { name: "Guinea", code: "+224" },
//     { name: "Guinea-Bissau", code: "+245" },
//     { name: "Ivory Coast (Côte d'Ivoire)", code: "+225" },
//     { name: "Kenya", code: "+254" },
//     { name: "Lesotho", code: "+266" },
//     { name: "Liberia", code: "+231" },
//     { name: "Libya", code: "+218" },
//     { name: "Madagascar", code: "+261" },
//     { name: "Malawi", code: "+265" },
//     { name: "Mali", code: "+223" },
//     { name: "Mauritania", code: "+222" },
//     { name: "Mauritius", code: "+230" },
//     { name: "Morocco", code: "+212" },
//     { name: "Mozambique", code: "+258" },
//     { name: "Namibia", code: "+264" },
//     { name: "Niger", code: "+227" },
//     { name: "Nigeria", code: "+234" },
//     { name: "Republic of the Congo", code: "+242" },
//     { name: "Rwanda", code: "+250" },
//     { name: "Sao Tome and Principe", code: "+239" },
//     { name: "Senegal", code: "+221" },
//     { name: "Seychelles", code: "+248" },
//     { name: "Sierra Leone", code: "+232" },
//     { name: "Somalia", code: "+252" },
//     { name: "South Africa", code: "+27" },
//     { name: "South Sudan", code: "+211" },
//     { name: "Sudan", code: "+249" },
//     { name: "Tanzania", code: "+255" },
//     { name: "Togo", code: "+228" },
//     { name: "Tunisia", code: "+216" },
//     { name: "Uganda", code: "+256" },
//     { name: "Zambia", code: "+260" },
//     { name: "Zimbabwe", code: "+263" },
//     { name: "Afghanistan", code: "+93" },
//     { name: "Armenia", code: "+374" },
//     { name: "Azerbaijan", code: "+994" },
//     { name: "Bahrain", code: "+973" },
//     { name: "Bangladesh", code: "+880" },
//     { name: "Bhutan", code: "+975" },
//     { name: "Brunei", code: "+673" },
//     { name: "Cambodia", code: "+855" },
//     { name: "China", code: "+86" },
//     { name: "Cyprus", code: "+357" },
//     { name: "Georgia", code: "+995" },
//     { name: "India", code: "+91" },
//     { name: "Indonesia", code: "+62" },
//     { name: "Iran", code: "+98" },
//     { name: "Iraq", code: "+964" },
//     { name: "Israel", code: "+972" },
//     { name: "Japan", code: "+81" },
//     { name: "Jordan", code: "+962" },
//     { name: "Kazakhstan", code: "+7" },
//     { name: "Kuwait", code: "+965" },
//     { name: "Kyrgyzstan", code: "+996" },
//     { name: "Laos", code: "+856" },
//     { name: "Lebanon", code: "+961" },
//     { name: "Malaysia", code: "+60" },
//     { name: "Maldives", code: "+960" },
//     { name: "Mongolia", code: "+976" },
//     { name: "Myanmar (Burma)", code: "+95" },
//     { name: "Nepal", code: "+977" },
//     { name: "North Korea", code: "+850" },
//     { name: "Oman", code: "+968" },
//     { name: "Pakistan", code: "+92" },
//     { name: "Palestine", code: "+970" },
//     { name: "Philippines", code: "+63" },
//     { name: "Qatar", code: "+974" },
//     { name: "Saudi Arabia", code: "+966" },
//     { name: "Singapore", code: "+65" },
//     { name: "South Korea", code: "+82" },
//     { name: "Sri Lanka", code: "+94" },
//     { name: "Syria", code: "+963" },
//     { name: "Taiwan", code: "+886" },
//     { name: "Tajikistan", code: "+992" },
//     { name: "Thailand", code: "+66" },
//     { name: "Timor-Leste", code: "+670" },
//     { name: "Turkey", code: "+90" },
//     { name: "Turkmenistan", code: "+993" },
//     { name: "United Arab Emirates (UAE)", code: "+971" },
//     { name: "Uzbekistan", code: "+998" },
//     { name: "Vietnam", code: "+84" },
//     { name: "Yemen", code: "+967" },
//     { name: "Albania", code: "+355" },
//     { name: "Andorra", code: "+376" },
//     { name: "Austria", code: "+43" },
//     { name: "Belarus", code: "+375" },
//     { name: "Belgium", code: "+32" },
//     { name: "Bosnia and Herzegovina", code: "+387" },
//     { name: "Bulgaria", code: "+359" },
//     { name: "Croatia", code: "+385" },
//     { name: "Czech Republic", code: "+420" },
//     { name: "Denmark", code: "+45" },
//     { name: "Estonia", code: "+372" },
//     { name: "Finland", code: "+358" },
//     { name: "France", code: "+33" },
//     { name: "Germany", code: "+49" },
//     { name: "Greece", code: "+30" },
//     { name: "Hungary", code: "+36" },
//     { name: "Iceland", code: "+354" },
//     { name: "Ireland", code: "+353" },
//     { name: "Italy", code: "+39" },
//     { name: "Latvia", code: "+371" },
//     { name: "Liechtenstein", code: "+423" },
//     { name: "Lithuania", code: "+370" },
//     { name: "Luxembourg", code: "+352" },
//     { name: "Malta", code: "+356" },
//     { name: "Moldova", code: "+373" },
//     { name: "Monaco", code: "+377" },
//     { name: "Montenegro", code: "+382" },
//     { name: "Netherlands", code: "+31" },
//     { name: "North Macedonia", code: "+389" },
//     { name: "Norway", code: "+47" },
//     { name: "Poland", code: "+48" },
//     { name: "Portugal", code: "+351" },
//     { name: "Romania", code: "+40" },
//     { name: "Russia", code: "+7" },
//     { name: "San Marino", code: "+378" },
//     { name: "Serbia", code: "+381" },
//     { name: "Slovakia", code: "+421" },
//     { name: "Slovenia", code: "+386" },
//     { name: "Spain", code: "+34" },
//     { name: "Sweden", code: "+46" },
//     { name: "Switzerland", code: "+41" },
//     { name: "Ukraine", code: "+380" },
//     { name: "United Kingdom (UK)", code: "+44" },
//     { name: "Vatican City", code: "+39" },
//     { name: "Antigua and Barbuda", code: "+1-268" },
//     { name: "Bahamas", code: "+1-242" },
//     { name: "Barbados", code: "+1-246" },
//     { name: "Belize", code: "+501" },
//     { name: "Canada", code: "+1" },
//     { name: "Costa Rica", code: "+506" },
//     { name: "Cuba", code: "+53" },
//     { name: "Dominica", code: "+1-767" },
//     { name: "Dominican Republic", code: "+1-809" },
//     { name: "El Salvador", code: "+503" },
//     { name: "Grenada", code: "+1-473" },
//     { name: "Guatemala", code: "+502" },
//     { name: "Haiti", code: "+509" },
//     { name: "Honduras", code: "+504" },
//     { name: "Jamaica", code: "+1-876" },
//     { name: "Mexico", code: "+52" },
//     { name: "Nicaragua", code: "+505" },
//     { name: "Panama", code: "+507" },
//     { name: "Saint Kitts and Nevis", code: "+1-869" },
//     { name: "Saint Lucia", code: "+1-758" },
//     { name: "Saint Vincent and the Grenadines", code: "+1-784" },
//     { name: "Trinidad and Tobago", code: "+1-868" },
//     { name: "United States of America (USA)", code: "+1" },
//     { name: "Australia", code: "+61" },
//     { name: "Fiji", code: "+679" },
//     { name: "Kiribati", code: "+686" },
//     { name: "Marshall Islands", code: "+692" },
//     { name: "Micronesia", code: "+691" },
//     { name: "Nauru", code: "+674" },
//     { name: "New Zealand", code: "+64" },
//     { name: "Palau", code: "+680" },
//     { name: "Papua New Guinea", code: "+675" },
//     { name: "Samoa", code: "+685" },
//     { name: "Solomon Islands", code: "+677" },
//     { name: "Tonga", code: "+676" },
//     { name: "Tuvalu", code: "+688" },
//     { name: "Vanuatu", code: "+678" },
//     { name: "Argentina", code: "+54" },
//     { name: "Bolivia", code: "+591" },
//     { name: "Brazil", code: "+55" },
//     { name: "Chile", code: "+56" },
//     { name: "Colombia", code: "+57" },
//     { name: "Ecuador", code: "+593" },
//     { name: "Guyana", code: "+592" },
//     { name: "Paraguay", code: "+595" },
//     { name: "Peru", code: "+51" },
//     { name: "Suriname", code: "+597" },
//     { name: "Uruguay", code: "+598" },
//     { name: "Venezuela", code: "+58" }
//   ];

//   return (
//     <section className="bg-blue-100 overflow-hidden relative">
//       <Navbar />
//       <div className="container grid grid-cols-1 md:grid-cols-2 min-h-[650px]">
//         {/* Brand Info */}
//         <div className="flex flex-col justify-center py-2 md:py-0 relative z-20">
     

// <div className="text-left md:text-left space-y-10 lg:max-w-[500px]">
//   <motion.h1 className="text-2xl lg:text-5xl font-bold !leading-snug">
//     <motion.span
//       key={currentHeadingIndex}
//       initial={{ opacity: 1 }} // Current heading fades out
//       animate={{ opacity: 0 }} // Fade out
//       transition={{ duration: 0.5 }}
//       exit={{ opacity: 0 }}
//     >
//       <AnimatedText text={headings[currentHeadingIndex]} />
//     </motion.span>
//     <motion.span
//       key={nextHeadingIndex}
//       initial={{ opacity: 0 }} // Next heading fades in
//       animate={{ opacity: 1 }} // Fade in
//       transition={{ duration: 0.5 }}
//       exit={{ opacity: 0 }}
//     >
//       <AnimatedText text={headings[nextHeadingIndex]} />
//     </motion.span>
//   </motion.h1>
//   <motion.div
//     initial={{ opacity: 0, y: 50 }}
//     animate={{ opacity: 1, y: 0 }}
//     transition={{ delay: 1.6, duration: 0.6, ease: "easeInOut" }}
//     className="flex justify-center md:justify-start"
//   >
//     <a href="/Brochure.pdf" download="Sankhyana_Brochure.pdf">
//       <button className="bg-blue-500 text-white flex items-center gap-2 group py-2 px-4 rounded transition duration-300 hover:bg-blue-600">
//         Download Brochure
//         <IoIosArrowRoundForward className="text-xl group-hover:translate-x-2 group-hover:-rotate-45 duration-300" />
//       </button>
//     </a>
//   </motion.div>
// </div>


          
//         </div>

//         {/* Animated Form Section */}
//         <div className="flex justify-center items-center">
//           <motion.div
//             {...FadeUp(0.4)} // Apply FadeUp animation with a delay
//             className="relative z-10 w-full max-w-md mx-auto bg-white shadow-2xl rounded-xl p-8 border border-gray-300 transition-transform duration-300 transform hover:scale-105 mb-10"
//           >
//             <h2 className="text-2xl font-bold mb-4 text-center">Register Now</h2>
         

//             <form onSubmit={handleSubmit} className="space-y-6">
//               <div className="mb-4">
//                 <label htmlFor="name" className="block text-sm font-semibold text-gray-700">
//                   Name:
//                 </label>
//                 <input
//                   type="text"
//                   id="name"
//                   name="name"
//                   value={formData.name}
//                   onChange={handleChange}
//                   required
//                   disabled={isLoading}
//                   className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.name ? 'border-red-600' : 'border-gray-300'}`}
//                 />
//                 {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
//               </div>

//               <div className="mb-4">
//                 <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
//                   Email:
//                 </label>
//                 <input
//                   type="email"
//                   id="email"
//                   name="email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   required
//                   disabled={isLoading}
//                   className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.email ? 'border-red-600' : 'border-gray-300'}`}
//                 />
//                 {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
//               </div>

//               {/* Country Field */}
//               <div className="mb-4">
//                 <label htmlFor="country" className="block text-sm font-semibold text-gray-700">
//                   Country:
//                 </label>
//                 <select
//                   id="country"
//                   name="country"
//                   value={formData.country}
//                   onChange={handleChange}
//                   disabled={isLoading}
//                   className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.country ? 'border-red-600' : 'border-gray-300'}`}
//                 >
//                   <option value="">Select a country</option>
//                   {countries.map((item, index) => (
//                     <option key={index} value={item.name}>{item.name}</option>
//                   ))}
//                 </select>
//                 {errors.country && <p className="text-red-600 text-sm">{errors.country}</p>}
//               </div>

//               {/* Phone Field with Country Code */}
//               <div className="mb-4">
//                 <label htmlFor="phone" className="block text-sm font-semibold text-gray-700">
//                   Phone:
//                 </label>
//                 <div className="flex">
//                   <input
//                     type="tel"
//                     id="phone"
//                     name="phone"
//                     value={formData.phone}
//                     onChange={handleChange}
//                     required
//                     disabled={isLoading}
//                     className={`block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.phone ? 'border-red-600' : 'border-gray-300'}`}
//                   />
//                 </div>
//                 {errors.phone && <p className="text-red-600 text-sm">{errors.phone}</p>}
//               </div>

//               <div className="mb-4">
//                 <label htmlFor="course" className="block text-sm font-semibold text-gray-700">
//                   Course:
//                 </label>
//                 <select
//                   id="course"
//                   name="course"
//                   value={formData.course}
//                   onChange={handleChange}
//                   disabled={isLoading}
//                   className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.course ? 'border-red-600' : 'border-gray-300'}`}
//                 >
//                   <option value="">Select a course</option>
//                   <option value="Data Science">Data Science</option>
//                   <option value="Data Engineering">Data Engineering</option>
//                   <option value="Full Stack Development">Full Stack Development</option>
//                 </select>
//                 {errors.course && <p className="text-red-600 text-sm">{errors.course}</p>}
//               </div>

          
//                <button
//                 type="submit"
//                 disabled={isLoading} // Disable button when loading
//                 className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
//               >
//                 {isLoading ? ( // Show spinner when loading
//                   <div className="flex items-center">
//                     <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
//                     <span>Loading...</span>
//                   </div>
//                 ) : (
//                   "Register"
//                 )}
//               </button>


//              </form>



//             {/* Message with FadeUp animation */}
//             {message && (
//               <motion.div
//                 {...FadeUp(0.6)} // Apply FadeUp animation with a delay
//                 className={`mt-4 p-3 text-center rounded ${messageType === 'success' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}
//               >
//                 {message}
//               </motion.div>
//             )}
//           </motion.div>

//           <motion.img
//             initial={{ x: -50, opacity: 0 }}
//             animate={{ x: 0, opacity: 1 }}
//             transition={{ duration: 0.6, delay: 0.2, ease: "easeInOut" }}
//             src={Blob}
//             alt="Blob Design"
//             className="absolute -bottom-32 w-[800px] md:w-[1500px] z-[1] hidden md:block"
//           />
//         </div>
//       </div>
//     </section>
//   );
// };

// // Ensure to export FadeUp along with the Hero component
// export default Hero;



































import React, { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { IoIosArrowRoundForward } from "react-icons/io";
import Blob from "../../assets/blob.svg";
import { motion } from "framer-motion";
import axios from "axios";
import { Landing, landingcreate } from "../../../../utils/constant";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";


// Helper function to split text into letters
const AnimatedText = ({ text }) => {
  const letters = Array.from(text);
  const [visibleLetters, setVisibleLetters] = useState(0); // Track visible letters

  useEffect(() => {
    if (visibleLetters < letters.length) {
      const timeout = setTimeout(() => {
        setVisibleLetters((prev) => prev + 1); // Increment visible letters one by one
      }, 30); // Adjusted typing speed for smoother animation

      return () => clearTimeout(timeout); // Cleanup the timeout
    }
  }, [visibleLetters, letters.length]);







  return (
    <div className="inline-block">
      {letters.slice(0, visibleLetters).map((letter, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0, y: "100%" }} // Corrected from y: 100% to y: "100%"
          animate={{ opacity: 1, y: 0 }}
          transition={{
            type: "spring",
            damping: 12,
            stiffness: 100,
            delay: index * 0.02, // Reduced delay for faster appearance
          }}
        >
          {letter}
        </motion.span>
      ))}
    </div>
  );
};

// FadeUp animation function
export const FadeUp = (delay) => {
  return {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        duration: 0.5,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};

const Hero = () => {

  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    // countryCode: "",
    country: "",
    course: "", // Default course
  };
  const [recaptchaToken, setRecaptchaToken] = useState(null); // Captcha token
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    // countryCode: "",
    country: "",
    course: "",
    serverError: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
      serverError: "",
    }));
  };

  const handleCaptchaChange = (token) => {
    if (token) {
      setRecaptchaToken(token); // Set the token when the CAPTCHA is solved
    } else {
      setRecaptchaToken(null); // Reset the token if CAPTCHA verification fails or expires
    }
  };
  

  const validateForm = () => {
    const { name, email, phone, country, course } = formData;
    const newErrors = {};

    if (!name.trim()) newErrors.name = "Please enter your name";
    if (!email.trim()) {
      newErrors.email = "Please enter your email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email.trim())
    ) {
      newErrors.email = "Please enter a valid email address";
    }
    // if (!countryCode.trim()) newErrors.countryCode = "Please select your country code";
    if (!phone.trim()) {
      newErrors.phone = "Please enter your phone number";
    } else if (!/^\d{7,15}$/.test(phone.trim())) {
      newErrors.phone = "Please enter a valid phone number";
    }
    if (!country.trim()) newErrors.country = "Please select your country";
    if (!course.trim()) newErrors.course = "Please select a course";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!recaptchaToken) {
      setMessageType("error");
      setMessage("Please complete the CAPTCHA.");
      return;
    }

    setIsLoading(true);
    setMessage("");

console.log("befor try form data",formData);
console.log("before try captcha",recaptchaToken);



    try {
      // Store Data
      const response = await axios.post(landingcreate, {
        ...formData,
        recaptchaToken,
      });

console.log("response",response);



      if (response.data.success) {
        setMessageType("success");
        setMessage("Form submitted successfully!");
        setFormData(initialFormData);
      } else {
        throw new Error(response.data.message || "Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessageType("error");
      setMessage("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  

  const headings = [
    "Get up to 90% off",
    "Unlock Your Future with Africa's Biggest Scholarship Program!",
    "Transform Your Dreams into Reality with Up to-Top-Quality Education!",
];


  const [currentHeadingIndex, setCurrentHeadingIndex] = useState(0); // Track current heading
  const [nextHeadingIndex, setNextHeadingIndex] = useState(1); // Track next heading

  useEffect(() => {
    const interval = setInterval(() => {
      setNextHeadingIndex((prevIndex) => (prevIndex + 1) % headings.length); // Calculate next heading
      setCurrentHeadingIndex((prevIndex) => (prevIndex + 1) % headings.length); // Update current heading
    }, 6000); // Change heading every 4 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [headings.length]);


  const countries = [
    { name: "Algeria", code: "+213" },
    { name: "Angola", code: "+244" },
    { name: "Benin", code: "+229" },
    { name: "Botswana", code: "+267" },
    { name: "Burkina Faso", code: "+226" },
    { name: "Burundi", code: "+257" },
    { name: "Cape Verde", code: "+238" },
    { name: "Cameroon", code: "+237" },
    { name: "Central African Republic", code: "+236" },
    { name: "Chad", code: "+235" },
    { name: "Comoros", code: "+269" },
    { name: "Democratic Republic of the Congo", code: "+243" },
    { name: "Djibouti", code: "+253" },
    { name: "Egypt", code: "+20" },
    { name: "Equatorial Guinea", code: "+240" },
    { name: "Eritrea", code: "+291" },
    { name: "Eswatini", code: "+268" },
    { name: "Ethiopia", code: "+251" },
    { name: "Gabon", code: "+241" },
    { name: "Gambia", code: "+220" },
    { name: "Ghana", code: "+233" },
    { name: "Guinea", code: "+224" },
    { name: "Guinea-Bissau", code: "+245" },
    { name: "Ivory Coast (Côte d'Ivoire)", code: "+225" },
    { name: "Kenya", code: "+254" },
    { name: "Lesotho", code: "+266" },
    { name: "Liberia", code: "+231" },
    { name: "Libya", code: "+218" },
    { name: "Madagascar", code: "+261" },
    { name: "Malawi", code: "+265" },
    { name: "Mali", code: "+223" },
    { name: "Mauritania", code: "+222" },
    { name: "Mauritius", code: "+230" },
    { name: "Morocco", code: "+212" },
    { name: "Mozambique", code: "+258" },
    { name: "Namibia", code: "+264" },
    { name: "Niger", code: "+227" },
    { name: "Nigeria", code: "+234" },
    { name: "Republic of the Congo", code: "+242" },
    { name: "Rwanda", code: "+250" },
    { name: "Sao Tome and Principe", code: "+239" },
    { name: "Senegal", code: "+221" },
    { name: "Seychelles", code: "+248" },
    { name: "Sierra Leone", code: "+232" },
    { name: "Somalia", code: "+252" },
    { name: "South Africa", code: "+27" },
    { name: "South Sudan", code: "+211" },
    { name: "Sudan", code: "+249" },
    { name: "Tanzania", code: "+255" },
    { name: "Togo", code: "+228" },
    { name: "Tunisia", code: "+216" },
    { name: "Uganda", code: "+256" },
    { name: "Zambia", code: "+260" },
    { name: "Zimbabwe", code: "+263" },
    { name: "Afghanistan", code: "+93" },
    { name: "Armenia", code: "+374" },
    { name: "Azerbaijan", code: "+994" },
    { name: "Bahrain", code: "+973" },
    { name: "Bangladesh", code: "+880" },
    { name: "Bhutan", code: "+975" },
    { name: "Brunei", code: "+673" },
    { name: "Cambodia", code: "+855" },
    { name: "China", code: "+86" },
    { name: "Cyprus", code: "+357" },
    { name: "Georgia", code: "+995" },
    { name: "India", code: "+91" },
    { name: "Indonesia", code: "+62" },
    { name: "Iran", code: "+98" },
    { name: "Iraq", code: "+964" },
    { name: "Israel", code: "+972" },
    { name: "Japan", code: "+81" },
    { name: "Jordan", code: "+962" },
    { name: "Kazakhstan", code: "+7" },
    { name: "Kuwait", code: "+965" },
    { name: "Kyrgyzstan", code: "+996" },
    { name: "Laos", code: "+856" },
    { name: "Lebanon", code: "+961" },
    { name: "Malaysia", code: "+60" },
    { name: "Maldives", code: "+960" },
    { name: "Mongolia", code: "+976" },
    { name: "Myanmar (Burma)", code: "+95" },
    { name: "Nepal", code: "+977" },
    { name: "North Korea", code: "+850" },
    { name: "Oman", code: "+968" },
    { name: "Pakistan", code: "+92" },
    { name: "Palestine", code: "+970" },
    { name: "Philippines", code: "+63" },
    { name: "Qatar", code: "+974" },
    { name: "Saudi Arabia", code: "+966" },
    { name: "Singapore", code: "+65" },
    { name: "South Korea", code: "+82" },
    { name: "Sri Lanka", code: "+94" },
    { name: "Syria", code: "+963" },
    { name: "Taiwan", code: "+886" },
    { name: "Tajikistan", code: "+992" },
    { name: "Thailand", code: "+66" },
    { name: "Timor-Leste", code: "+670" },
    { name: "Turkey", code: "+90" },
    { name: "Turkmenistan", code: "+993" },
    { name: "United Arab Emirates (UAE)", code: "+971" },
    { name: "Uzbekistan", code: "+998" },
    { name: "Vietnam", code: "+84" },
    { name: "Yemen", code: "+967" },
    { name: "Albania", code: "+355" },
    { name: "Andorra", code: "+376" },
    { name: "Austria", code: "+43" },
    { name: "Belarus", code: "+375" },
    { name: "Belgium", code: "+32" },
    { name: "Bosnia and Herzegovina", code: "+387" },
    { name: "Bulgaria", code: "+359" },
    { name: "Croatia", code: "+385" },
    { name: "Czech Republic", code: "+420" },
    { name: "Denmark", code: "+45" },
    { name: "Estonia", code: "+372" },
    { name: "Finland", code: "+358" },
    { name: "France", code: "+33" },
    { name: "Germany", code: "+49" },
    { name: "Greece", code: "+30" },
    { name: "Hungary", code: "+36" },
    { name: "Iceland", code: "+354" },
    { name: "Ireland", code: "+353" },
    { name: "Italy", code: "+39" },
    { name: "Latvia", code: "+371" },
    { name: "Liechtenstein", code: "+423" },
    { name: "Lithuania", code: "+370" },
    { name: "Luxembourg", code: "+352" },
    { name: "Malta", code: "+356" },
    { name: "Moldova", code: "+373" },
    { name: "Monaco", code: "+377" },
    { name: "Montenegro", code: "+382" },
    { name: "Netherlands", code: "+31" },
    { name: "North Macedonia", code: "+389" },
    { name: "Norway", code: "+47" },
    { name: "Poland", code: "+48" },
    { name: "Portugal", code: "+351" },
    { name: "Romania", code: "+40" },
    { name: "Russia", code: "+7" },
    { name: "San Marino", code: "+378" },
    { name: "Serbia", code: "+381" },
    { name: "Slovakia", code: "+421" },
    { name: "Slovenia", code: "+386" },
    { name: "Spain", code: "+34" },
    { name: "Sweden", code: "+46" },
    { name: "Switzerland", code: "+41" },
    { name: "Ukraine", code: "+380" },
    { name: "United Kingdom (UK)", code: "+44" },
    { name: "Vatican City", code: "+39" },
    { name: "Antigua and Barbuda", code: "+1-268" },
    { name: "Bahamas", code: "+1-242" },
    { name: "Barbados", code: "+1-246" },
    { name: "Belize", code: "+501" },
    { name: "Canada", code: "+1" },
    { name: "Costa Rica", code: "+506" },
    { name: "Cuba", code: "+53" },
    { name: "Dominica", code: "+1-767" },
    { name: "Dominican Republic", code: "+1-809" },
    { name: "El Salvador", code: "+503" },
    { name: "Grenada", code: "+1-473" },
    { name: "Guatemala", code: "+502" },
    { name: "Haiti", code: "+509" },
    { name: "Honduras", code: "+504" },
    { name: "Jamaica", code: "+1-876" },
    { name: "Mexico", code: "+52" },
    { name: "Nicaragua", code: "+505" },
    { name: "Panama", code: "+507" },
    { name: "Saint Kitts and Nevis", code: "+1-869" },
    { name: "Saint Lucia", code: "+1-758" },
    { name: "Saint Vincent and the Grenadines", code: "+1-784" },
    { name: "Trinidad and Tobago", code: "+1-868" },
    { name: "United States of America (USA)", code: "+1" },
    { name: "Australia", code: "+61" },
    { name: "Fiji", code: "+679" },
    { name: "Kiribati", code: "+686" },
    { name: "Marshall Islands", code: "+692" },
    { name: "Micronesia", code: "+691" },
    { name: "Nauru", code: "+674" },
    { name: "New Zealand", code: "+64" },
    { name: "Palau", code: "+680" },
    { name: "Papua New Guinea", code: "+675" },
    { name: "Samoa", code: "+685" },
    { name: "Solomon Islands", code: "+677" },
    { name: "Tonga", code: "+676" },
    { name: "Tuvalu", code: "+688" },
    { name: "Vanuatu", code: "+678" },
    { name: "Argentina", code: "+54" },
    { name: "Bolivia", code: "+591" },
    { name: "Brazil", code: "+55" },
    { name: "Chile", code: "+56" },
    { name: "Colombia", code: "+57" },
    { name: "Ecuador", code: "+593" },
    { name: "Guyana", code: "+592" },
    { name: "Paraguay", code: "+595" },
    { name: "Peru", code: "+51" },
    { name: "Suriname", code: "+597" },
    { name: "Uruguay", code: "+598" },
    { name: "Venezuela", code: "+58" }
  ];

  return (
    <section className="bg-blue-100 overflow-hidden relative">
      <Navbar />
      <div className="container grid grid-cols-1 md:grid-cols-2 min-h-[650px]">
        {/* Brand Info */}
        <div className="flex flex-col justify-center py-2 md:py-0 relative z-20">
     

<div className="text-left md:text-left space-y-10 lg:max-w-[500px]">
  <motion.h1 className="text-2xl lg:text-5xl font-bold !leading-snug">
    <motion.span
      key={currentHeadingIndex}
      initial={{ opacity: 1 }} // Current heading fades out
      animate={{ opacity: 0 }} // Fade out
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <AnimatedText text={headings[currentHeadingIndex]} />
    </motion.span>
    <motion.span
      key={nextHeadingIndex}
      initial={{ opacity: 0 }} // Next heading fades in
      animate={{ opacity: 1 }} // Fade in
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
    >
      <AnimatedText text={headings[nextHeadingIndex]} />
    </motion.span>
  </motion.h1>
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 1.6, duration: 0.6, ease: "easeInOut" }}
    className="flex justify-center md:justify-start"
  >
    <a href="/Brochure.pdf" download="Sankhyana_Brochure.pdf">
      <button className="bg-blue-500 text-white flex items-center gap-2 group py-2 px-4 rounded transition duration-300 hover:bg-blue-600">
        Download Brochure
        <IoIosArrowRoundForward className="text-xl group-hover:translate-x-2 group-hover:-rotate-45 duration-300" />
      </button>
    </a>
  </motion.div>
</div>


          
        </div>

        {/* Animated Form Section */}
        <div className="flex justify-center items-center">
          <motion.div
            {...FadeUp(0.4)} // Apply FadeUp animation with a delay
            className="relative z-10 w-full max-w-md mx-auto bg-white shadow-2xl rounded-xl p-8 border border-gray-300 transition-transform duration-300 transform hover:scale-105 mb-10"
          >
            <h2 className="text-2xl font-bold mb-4 text-center">Register Now</h2>
         

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-semibold text-gray-700">
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  disabled={isLoading}
                  className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.name ? 'border-red-600' : 'border-gray-300'}`}
                />
                {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-semibold text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={isLoading}
                  className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.email ? 'border-red-600' : 'border-gray-300'}`}
                />
                {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
              </div>

              {/* Country Field */}
              <div className="mb-4">
                <label htmlFor="country" className="block text-sm font-semibold text-gray-700">
                  Country:
                </label>
                <select
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  disabled={isLoading}
                  className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.country ? 'border-red-600' : 'border-gray-300'}`}
                >
                  <option value="">Select a country</option>
                  {countries.map((item, index) => (
                    <option key={index} value={item.name}>{item.name}</option>
                  ))}
                </select>
                {errors.country && <p className="text-red-600 text-sm">{errors.country}</p>}
              </div>

              {/* Phone Field with Country Code */}
              <div className="mb-4">
                <label htmlFor="phone" className="block text-sm font-semibold text-gray-700">
                  Phone:
                </label>
                <div className="flex">
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    disabled={isLoading}
                    className={`block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.phone ? 'border-red-600' : 'border-gray-300'}`}
                  />
                </div>
                {errors.phone && <p className="text-red-600 text-sm">{errors.phone}</p>}
              </div>

              <div className="mb-4">
                <label htmlFor="course" className="block text-sm font-semibold text-gray-700">
                  Course:
                </label>
                <select
                  id="course"
                  name="course"
                  value={formData.course}
                  onChange={handleChange}
                  disabled={isLoading}
                  className={`mt-1 block w-full border rounded-md p-3 focus:outline-none focus:ring-2 focus:ring-blue-400 transition duration-200 bg-gray-50 shadow-sm hover:shadow-md ${errors.course ? 'border-red-600' : 'border-gray-300'}`}
                >
                  <option value="">Select a course</option>
                  <option value="Data Science">Data Science</option>
                  <option value="Data Engineering">Data Engineering</option>
                  <option value="Full Stack Development">Full Stack Development</option>
                </select>
                {errors.course && <p className="text-red-600 text-sm">{errors.course}</p>}
              </div>

          
 


<ReCAPTCHA
  sitekey="6LfUXI4qAAAAAIOJrktxysW1FCXKEWZerKazMRfn"
  onChange={handleCaptchaChange}
  onExpired={() => setRecaptchaToken(null)} // Reset token if expired
/>


               <button
                type="submit"
                disabled={isLoading} // Disable button when loading
                className="w-full bg-blue-600 text-white py-3 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center"
              >
                {isLoading ? ( // Show spinner when loading
                  <div className="flex items-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                    <span>Loading...</span>
                  </div>
                ) : (
                  "Register"
                )}
              </button>


             </form>



            {/* Message with FadeUp animation */}
            {message && (
              <motion.div
                {...FadeUp(0.6)} // Apply FadeUp animation with a delay
                className={`mt-4 p-3 text-center rounded ${messageType === 'success' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}
              >
                {message}
              </motion.div>
            )}
          </motion.div>

          <motion.img
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2, ease: "easeInOut" }}
            src={Blob}
            alt="Blob Design"
            className="absolute -bottom-32 w-[800px] md:w-[1500px] z-[1] hidden md:block"
          />
        </div>
      </div>
    </section>
  );
};

// Ensure to export FadeUp along with the Hero component
export default Hero;












































