export const BaseUrl = "https://www.sankhyana.com/"
export const login = "https://www.sankhyana.com/login/login"


// Batch
export const createBatch = "https://www.sankhyana.com/createBatch"
export const getAllBatch = "https://www.sankhyana.com/getAllBatch"
export const getBatchBYID = "https://www.sankhyana.com/getBatchBYID/"
export const deleteBatch = "https://www.sankhyana.com/deleteBatch/"
export const updateBatch= "https://www.sankhyana.com/updateBatch/"

                                                                    

// Blog
export const createBlog = "https://www.sankhyana.com/createBlog"
export const getAllBlog="https://www.sankhyana.com/getAllBlog"
export const createBlogDetail= "https://www.sankhyana.com/createBlogDetail"
export const getAllBlogDetail= "https://www.sankhyana.com/getAllBlogDetail"
export const deleteBlogDetail= "https://www.sankhyana.com/deleteBlogDetail/"
export const getAllBlogDetailBYID= "https://www.sankhyana.com/getAllBlogDetailBYID/"

// career
export const createCareer = "https://www.sankhyana.com/createCareer"
export const getAllCareer= "https://www.sankhyana.com/getAllCareer"
export const deleteCareer= "https://www.sankhyana.com/deleteCareer/"
export const getCareerBYID= "https://www.sankhyana.com/getCareerBYID/"
export const updateCareer= "https://www.sankhyana.com/updateCareer/"

// Hiring
export const createHiring= "https://www.sankhyana.com/createHiring"
export const getAllHiring= "https://www.sankhyana.com/getAllHiring"
export const deleteHiring= "https://www.sankhyana.com/deleteHiring/"
export const updateHiring = "https://www.sankhyana.com/updateHiring/"

// offer
export const createOffer= "https://www.sankhyana.com/createOffer"
export const getAllOffer= "https://www.sankhyana.com/getOffer"
export const deleteOffer= "https://www.sankhyana.com/deleteOffer/"
export const updateOffer= "https://www.sankhyana.com/updateOffer/"

// Recent News
export const createRecent= "https://www.sankhyana.com/createRecent"
export const getRecent= "https://www.sankhyana.com/getAllRecent"
export const deleteRecent= "https://www.sankhyana.com/deleteRecent/"


// Training
export const getAllTraining= "https://www.sankhyana.com/getAllTraining"
export const deleteTraining= "https://www.sankhyana.com/deleteTraining/"
export const createTraining= "https://www.sankhyana.com/createTraining"
export const createTrainingDetail= "https://www.sankhyana.com/createTrainingDetail"
export const getAllTDetail= "https://www.sankhyana.com/getAllTDetail"



// mail databse
export const contactus = "https://www.sankhyana.com/contact/contactus"
export const connectnow= "https://www.sankhyana.com/connect/connectnow"
export const enrollnow = "https://www.sankhyana.com/Enroll/createenroll"
export const landingcreate = "https://www.sankhyana.com/api/form/register"

 // mail 

 export const Landing = "https://www.sankhyana.com/api/form/register"

 export const Enroll = "https://www.sankhyana.com/Enroll/Enroll"
export const contact = "https://www.sankhyana.com/contact/contact"
export const apply = "https://www.sankhyana.com/apply/apply"
export const connect = "https://www.sankhyana.com/connect/connect"

export const visit = "https://www.sankhyana.com/visit/"



export const createlanding = "https://www.sankhyana.com/createlanding"







