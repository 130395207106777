// import React, { Suspense, lazy, useEffect, useState } from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import { RouterProvider, createBrowserRouter } from 'react-router-dom';
// import EnrollNow from './Pages/Training/EnrollNow.js';
// import Lms from './Component/LMS/Lms.js';
// import Privacy from './Component/Pricavy/Privacy.js';
// import Term from './Component/Term/Term.js';
// import Error from './Component/Error/Error.js';
// import Student from './Pages/Hiring/Student.js';
// const UpdateOffer = lazy(()=> import("./Admin/AdminPages/Offer/UpdateOffer"))
// const AddBatch = lazy(()=> import("./Admin/AdminPages/Batch/AddBatch"))
// const InfoBatch = lazy(()=> import("./Admin/AdminPages/Batch/InfoBatch"))
// const UpdateBatch = lazy(()=> import("./Admin/AdminPages/Batch/UpdateBatch"))
// const InfoTraining = lazy(()=> import("./Admin/AdminPages/Training/InfoTraining.js"))
// const HomeTraining = lazy(()=> import("./Admin/AdminPages/Training/HomeTraining.js"))
// const TDetails = lazy(()=> import("./Admin/AdminPages/Training/TrainingDetails.js"))
// const ACareer = lazy(()=> import("./Admin/AdminPages/Career/Career.js"))
// const InfoCareer = lazy(()=> import("./Admin/AdminPages/Career/InfoCareer.js"))
// const UpdateCareer = lazy(()=> import("./Admin/AdminPages/Career/UpdateCareer.js"))
// const UpdateTraining = lazy(()=> import("./Admin/AdminPages/Training/UpdateTraining.js"))
// const ABlog = lazy(()=> import("./Admin/AdminPages/Blog/Blog.js"))
// const HomeBlog = lazy(()=> import("./Admin/AdminPages/Blog/HomeBlog.js"))
// const BlogDetails = lazy(()=> import("./Admin/AdminPages/Blog/BlogDetails.js"))
// const InfoBlog = lazy(()=> import("./Admin/AdminPages/Blog/InfoBlog.js"))
// const AHiring = lazy(()=> import("./Admin/AdminPages/Hiring/Hiring.js"))
// const InfoHiring = lazy(()=> import("./Admin/AdminPages/Hiring/InfoHiring.js"))
// const ItDevelopment = lazy(()=> import("./Pages/ITSoulation/ItDevelopment.js"))
// const Recent = lazy(()=> import("./Admin/AdminPages/RecentNews/Recent.js"))
// const InfoNews = lazy(()=> import("./Admin/AdminPages/RecentNews/InfoNews.js"))
// const RetailDetail = lazy(()=> import('./Pages/Analytics/RetailDetails.js'))
// const ItDigital = lazy(()=> import("./Pages/ITSoulation/Itdigital.js"))


// const BankAnalytics = lazy(()=> import("./Pages/Analytics/BankAnalytics.js"))
// const FincialDetails = lazy(()=> import("./Pages/Analytics/FincialDetails.js"))
// const CloudDetails = lazy(()=> import("./Pages/Analytics/CloudDetails.js"))
// const DetaDetails = lazy(()=> import("./Pages/Analytics/DetaDetails.js"))
// const AutoDetails = lazy(()=> import("./Pages/Analytics/AutoDetails.js"))
// const TeleDetails = lazy(()=> import("./Pages/Analytics/TeleDetails.js"))
// const Insurance = lazy(()=> import("./Pages/Analytics/InsuranceDetail.js"))
// const Life = lazy(()=> import("./Pages/Analytics/LifeDetail.js"))
// const Social = lazy(()=> import("./Pages/Analytics/SocialDetail.js"))


// const Layout = lazy(() => import('./Pages/Layout'));
// const Home = lazy(() => import('./Pages/Home/Home'));
// const About = lazy(() => import('./Pages/About/About'));
// const Training = lazy(() => import('./Pages/Training/Training'));
// const Consulting = lazy(() => import('./Pages/Consulting/Consulting'));
// const ConsultingDetails = lazy(() => import('./Pages/Consulting/ConsultingDetails'));
// const TrainingDetail = lazy(() => import('./Pages/Training/TrainingDetail'));
// const Analytics = lazy(() => import('./Pages/Analytics/Analytics'));
// const AnalyticsDetail = lazy(() => import('./Pages/Analytics/AnalyticsDetail'));
// const Itsoulation = lazy(() => import('./Pages/ITSoulation/Itsoulation'));
// const Hiring = lazy(() => import('./Pages/Hiring/Hiring'));
// const Career = lazy(() => import('./Pages/Career/Career'));
// const CareerDetail = lazy(() => import('./Pages/Career/CareerDetail'));
// const Contact = lazy(() => import('./Pages/Contactus/contact'));
// const Blog = lazy(() => import('./Pages/Blog/Blog'));
// const BlogDetail = lazy(() => import('./Pages/Blog/BlogDetail'));
// const BlogAll = lazy(() => import('./Pages/Blog/BlogAll'));
// const BlogAllCategory = lazy(() => import('./Pages/Blog/BlogAllCategory'))



// const Login = lazy(() => import('./Component/Login/login'));
// const Auth = lazy(() => import('./Component/Login/Auth'));
// const Sidebar = lazy(() => import('./Admin/AdminComponent/Sidebar'));
// const Dashboard = lazy(() => import('./Admin/Dashboard'));
// const AddOffer = lazy(() => import('./Admin/AdminPages/Offer/AddOffer'));
// const InfoOffer = lazy(() => import('./Admin/AdminPages/Offer/InfoOffer'));
// const AddTraining = lazy(() => import('./Admin/AdminPages/Training/Training.js'))
// const Thankyou = lazy(() => import('./Component/Thankyou/Thankyou.js'))

// const LMSComing = lazy(()=> import('./Component/LMS/LMSComing.js'))

// const [showLMSComing, setShowLMSComing] = useState(true);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setShowLMSComing(false);
//     }, 5000); // Show LMSComing for 5 seconds

//     return () => clearTimeout(timer); // Cleanup timer on component unmount
//   }, []);



// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Layout />,
//     errorElement: <Error/>,
   
   
//     children: [
    
//       // { index: true, element:<Suspense fallback={<h1>Loading ..............</h1>}><Home/> </Suspense> },
//       { index: true, element:<Suspense fallback={<h1>Loading ..............</h1>}> 
//        {showLMSComing ? <LMSComing/>: <Home />}
//        </Suspense> },
   
//       { path: "/about", element: <Suspense fallback={<h1>Loading ..............</h1>}><About/> </Suspense> },
//       { path: "/training", element: <Suspense fallback={<h1>Loading ..............</h1>}><Training/> </Suspense> },
//       { path: "/consulting", element: <Suspense fallback={<h1>Loading ..............</h1>}><Consulting/> </Suspense> },
//       { path: "/consultingdetails", element: <Suspense fallback={<h1>Loading ..............</h1>}><ConsultingDetails/> </Suspense> },
//       { path: "/courseDetail/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}> <TrainingDetail/></Suspense> },
//       { path: "/analytics", element: <Suspense fallback={<h1>Loading ..............</h1>}><Analytics/></Suspense> },
//       { path: "/detail", element: <Suspense fallback={<h1>Loading ..............</h1>}><AnalyticsDetail/></Suspense> },
//       { path: "/it", element:<Suspense fallback={<h1>Loading ..............</h1>}><Itsoulation/> </Suspense> },
//        { path: "/itdev", element: <Suspense fallback={<h1>Loading ..............</h1>}><ItDevelopment/> </Suspense> },
//       { path: "/itdigital", element: <Suspense fallback={<h1>Loading ..............</h1>}><ItDigital/> </Suspense> },
      
//       { path: "/hiring", element: <Suspense fallback={<h1>Loading ..............</h1>}><Hiring/> </Suspense>},
//       { path: "/hiring/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><Student/> </Suspense>},
      
//       { path: "/career", element: <Suspense fallback={<h1>Loading ..............</h1>}><Career/> </Suspense>},
//       { path: "/opening/:job", element: <Suspense fallback={<h1>Loading ..............</h1>}><CareerDetail/> </Suspense> },
//       { path: "/contact", element: <Suspense fallback={<h1>Loading ..............</h1>}><Contact/> </Suspense>},
//       { path: "/Blog", element: <Suspense fallback={<h1>Loading ..............</h1>}><Blog /></Suspense> },
//       // { path: "/BlogDetail/:id", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogDetail/> </Suspense> },

//       { path: "/BlogDetail/:title", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogDetail/> </Suspense> },

//       { path: "/BlogAll", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogAll/> </Suspense> },
//       { path: "/BlogAllCategory/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogAllCategory/> </Suspense> },
     
//       { path: "/lms", element: <Suspense fallback={<h1>Loading ..............</h1>}><Lms/> </Suspense> },
      
     
//       // analytics

//       { path: "/Bank", element: <Suspense fallback={<h1>Loading ..............</h1>}><BankAnalytics/></Suspense> },
//       { path: "/Financial", element: <Suspense fallback={<h1>Loading ..............</h1>}><FincialDetails/> </Suspense> },
//       {path:"/Cloud", element:<Suspense fallback={<h1>Loading...........</h1>}><CloudDetails/> </Suspense>},
//       {path:"/Data", element:<Suspense fallback={<h1>Loading...........</h1>}><DetaDetails/> </Suspense>},
//       { path: "/Auto", element: <Suspense fallback={<h1>Loading ..............</h1>}><AutoDetails/></Suspense> },
//       {path:"/Retail", element:<Suspense fallback={<h1>Loading...........</h1>}><RetailDetail/> </Suspense>},
//       {path:"/Tele", element:<Suspense fallback={<h1>Loading...........</h1>}><TeleDetails/> </Suspense>},
      
      
//       { path: "/insurance", element: <Suspense fallback={<h1>Loading ..............</h1>}><Insurance/> </Suspense> },
//       {path:"/life", element:<Suspense fallback={<h1>Loading...........</h1>}><Life/> </Suspense>},
//       {path:"/social", element:<Suspense fallback={<h1>Loading...........</h1>}><Social/> </Suspense>},
      
      
//       { path: "/Thankyou", element: <Suspense fallback={<h1>Loading ..............</h1>}><Thankyou/> </Suspense> },
//       { path: "/privacy", element: <Suspense fallback={<h1>Loading ..............</h1>}><Privacy/> </Suspense> },
//       { path: "/term", element: <Suspense fallback={<h1>Loading ..............</h1>}><Term/> </Suspense> },
      
     
//       { path: "/Enroll/:batchname", element: <Suspense fallback={<h1>Loading ..............</h1>}><EnrollNow/> </Suspense> },
  
//     ]
//   },
 

//   { path: "/coming", element: <Suspense fallback={<h1>Loading ..............</h1>}><LMSComing/> </Suspense> },
     

//   {
//     path: "/error",
//     element: <Suspense fallback={<h1>Loading ..............</h1>}><Error/> </Suspense>
//   },

//   {
//   path: "/admin",
//   element: <Suspense fallback={<h1>Loading ..............</h1>}><Login /> </Suspense>
// },
// {
//   path: "/Dashboard",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['admin', 'user']}>
//         <Sidebar>
//           <Dashboard />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// // Offer routes
// {
//   path: "/Add Offer",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Offer Editior']}>
//         <Sidebar>
//           <AddOffer />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info Offer",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Offer Editior']}>
//         <Sidebar>
//           <InfoOffer />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Update/:id",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Offer Editior']}>
//         <Sidebar>
//           <UpdateOffer />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// // Batch routes
// {
//   path: "/Add Batch",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Batch Editior']}>
//         <Sidebar>
//           <AddBatch />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info Batch",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Batch Editior']}>
//         <Sidebar>
//           <InfoBatch />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/UpdateBatch/:id",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Batch Editior']}>
//         <Sidebar>
//           <UpdateBatch />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// // Training routes
// {
//   path: "/HomeTraining",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Training Editior']}>
//         <Sidebar>
//           <HomeTraining />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Add Training",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Training Editior']}>
//         <Sidebar>
//           <AddTraining />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/TDetail",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Training Editior']}>
//         <Sidebar>
//           <TDetails />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info Training",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Training Editior']}>
//         <Sidebar>
//           <InfoTraining />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/UpdateTDetail/:id",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Training Editior']}>
//         <Sidebar>
//           <UpdateTraining />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// // Career routes
// {
//   path: "/Add Career",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Career Editior']}>
//         <Sidebar>
//           <ACareer />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info Career",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Career Editior']}>
//         <Sidebar>
//           <InfoCareer />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/UpdateCareer/:id",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Career Editior']}>
//         <Sidebar>
//           <UpdateCareer />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// // Blog routes
// {
//   path: "/HomeBlog",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Blog Editior']}>
//         <Sidebar>
//           <HomeBlog />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Add Blog",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Blog Editior']}>
//         <Sidebar>
//           <ABlog />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/BDetail",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Blog Editior']}>
//         <Sidebar>
//           <BlogDetails />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info Blog",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Blog Editior']}>
//         <Sidebar>
//           <InfoBlog />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// // Hiring routes
// {
//   path: "/Add Hiring",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Hiring Editior']}>
//         <Sidebar>
//           <AHiring />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info Hiring",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Hiring Editior']}>
//         <Sidebar>
//           <InfoHiring />
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },




// // Recent News
// {
//   path: "/Add News",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Recent Editior']}>
//         <Sidebar>
//         <Recent/>
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },
// {
//   path: "/Info News",
//   element: (
//     <Suspense fallback={<h1>Loading ..............</h1>}>
//       <Auth roles={['Recent Editior']}>
//         <Sidebar>
//         <InfoNews/>
//         </Sidebar>
//       </Auth>
//     </Suspense>
//   )
// },

// ]);





// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <div>
//     <RouterProvider router={router} />
//   </div>
// );































// import React, { Suspense, lazy, useEffect, useState } from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import { RouterProvider, createBrowserRouter } from 'react-router-dom';
// import EnrollNow from './Pages/Training/EnrollNow.js';
// import Lms from './Component/LMS/Lms.js';
// import Privacy from './Component/Pricavy/Privacy.js';
// import Term from './Component/Term/Term.js';
// import Error from './Component/Error/Error.js';
// import Student from './Pages/Hiring/Student.js';
// const UpdateOffer = lazy(()=> import("./Admin/AdminPages/Offer/UpdateOffer"))
// const AddBatch = lazy(()=> import("./Admin/AdminPages/Batch/AddBatch"))
// const InfoBatch = lazy(()=> import("./Admin/AdminPages/Batch/InfoBatch"))
// const UpdateBatch = lazy(()=> import("./Admin/AdminPages/Batch/UpdateBatch"))
// const InfoTraining = lazy(()=> import("./Admin/AdminPages/Training/InfoTraining.js"))
// const HomeTraining = lazy(()=> import("./Admin/AdminPages/Training/HomeTraining.js"))
// const TDetails = lazy(()=> import("./Admin/AdminPages/Training/TrainingDetails.js"))
// const ACareer = lazy(()=> import("./Admin/AdminPages/Career/Career.js"))
// const InfoCareer = lazy(()=> import("./Admin/AdminPages/Career/InfoCareer.js"))
// const UpdateCareer = lazy(()=> import("./Admin/AdminPages/Career/UpdateCareer.js"))
// const UpdateTraining = lazy(()=> import("./Admin/AdminPages/Training/UpdateTraining.js"))
// const ABlog = lazy(()=> import("./Admin/AdminPages/Blog/Blog.js"))
// const HomeBlog = lazy(()=> import("./Admin/AdminPages/Blog/HomeBlog.js"))
// const BlogDetails = lazy(()=> import("./Admin/AdminPages/Blog/BlogDetails.js"))
// const InfoBlog = lazy(()=> import("./Admin/AdminPages/Blog/InfoBlog.js"))
// const AHiring = lazy(()=> import("./Admin/AdminPages/Hiring/Hiring.js"))
// const InfoHiring = lazy(()=> import("./Admin/AdminPages/Hiring/InfoHiring.js"))
// const ItDevelopment = lazy(()=> import("./Pages/ITSoulation/ItDevelopment.js"))
// const Recent = lazy(()=> import("./Admin/AdminPages/RecentNews/Recent.js"))
// const InfoNews = lazy(()=> import("./Admin/AdminPages/RecentNews/InfoNews.js"))
// const RetailDetail = lazy(()=> import('./Pages/Analytics/RetailDetails.js'))
// const ItDigital = lazy(()=> import("./Pages/ITSoulation/Itdigital.js"))


// const BankAnalytics = lazy(()=> import("./Pages/Analytics/BankAnalytics.js"))
// const FincialDetails = lazy(()=> import("./Pages/Analytics/FincialDetails.js"))
// const CloudDetails = lazy(()=> import("./Pages/Analytics/CloudDetails.js"))
// const DetaDetails = lazy(()=> import("./Pages/Analytics/DetaDetails.js"))
// const AutoDetails = lazy(()=> import("./Pages/Analytics/AutoDetails.js"))
// const TeleDetails = lazy(()=> import("./Pages/Analytics/TeleDetails.js"))
// const Insurance = lazy(()=> import("./Pages/Analytics/InsuranceDetail.js"))
// const Life = lazy(()=> import("./Pages/Analytics/LifeDetail.js"))
// const Social = lazy(()=> import("./Pages/Analytics/SocialDetail.js"))


// const Layout = lazy(() => import('./Pages/Layout'));
// const Home = lazy(() => import('./Pages/Home/Home'));
// const About = lazy(() => import('./Pages/About/About'));
// const Training = lazy(() => import('./Pages/Training/Training'));
// const Consulting = lazy(() => import('./Pages/Consulting/Consulting'));
// const ConsultingDetails = lazy(() => import('./Pages/Consulting/ConsultingDetails'));
// const TrainingDetail = lazy(() => import('./Pages/Training/TrainingDetail'));
// const Analytics = lazy(() => import('./Pages/Analytics/Analytics'));
// const AnalyticsDetail = lazy(() => import('./Pages/Analytics/AnalyticsDetail'));
// const Itsoulation = lazy(() => import('./Pages/ITSoulation/Itsoulation'));
// const Hiring = lazy(() => import('./Pages/Hiring/Hiring'));
// const Career = lazy(() => import('./Pages/Career/Career'));
// const CareerDetail = lazy(() => import('./Pages/Career/CareerDetail'));
// const Contact = lazy(() => import('./Pages/Contactus/contact'));
// const Blog = lazy(() => import('./Pages/Blog/Blog'));
// const BlogDetail = lazy(() => import('./Pages/Blog/BlogDetail'));
// const BlogAll = lazy(() => import('./Pages/Blog/BlogAll'));
// const BlogAllCategory = lazy(() => import('./Pages/Blog/BlogAllCategory'))



// const Login = lazy(() => import('./Component/Login/login'));
// const Auth = lazy(() => import('./Component/Login/Auth'));
// const Sidebar = lazy(() => import('./Admin/AdminComponent/Sidebar'));
// const Dashboard = lazy(() => import('./Admin/Dashboard'));
// const AddOffer = lazy(() => import('./Admin/AdminPages/Offer/AddOffer'));
// const InfoOffer = lazy(() => import('./Admin/AdminPages/Offer/InfoOffer'));
// const AddTraining = lazy(() => import('./Admin/AdminPages/Training/Training.js'))
// const Thankyou = lazy(() => import('./Component/Thankyou/Thankyou.js'))

// const LMSComing = lazy(()=> import('./Component/LMS/LMSComing.js'))


// // Functional component for managing state and effects
// const App = () => {
//     const [showLMSComing, setShowLMSComing] = useState(true);
  
//     useEffect(() => {
//       const timer = setTimeout(() => {
//         setShowLMSComing(false);
//       }, 90000); // Show LMSComing for 5 seconds
  
//       return () => clearTimeout(timer); // Cleanup timer on component unmount
//     }, []);
  
//     // Define a generic error component if not already defined
//     const Error = () => <div>Error: Page not found!</div>;
  
//     // Router configuration
//     const router = createBrowserRouter([
//       {
//         path: "/",
//         element: <Layout />,
//         errorElement: <Error />,
//         children: [
//           {
//             index: true,
//             element: (
//               <Suspense fallback={<h1>Loading ..............</h1>}>
//                 {showLMSComing ? <LMSComing /> : <Home />}
//               </Suspense>
//             ),
//           },
//           { path: "/about", element: <Suspense fallback={<h1>Loading ..............</h1>}><About /></Suspense> },
//           { path: "/training", element: <Suspense fallback={<h1>Loading ..............</h1>}><Training /></Suspense> },
//           { path: "/consulting", element: <Suspense fallback={<h1>Loading ..............</h1>}><Consulting /></Suspense> },
//           { path: "/consultingdetails", element: <Suspense fallback={<h1>Loading ..............</h1>}><ConsultingDetails /></Suspense> },
//           { path: "/courseDetail/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><TrainingDetail /></Suspense> },
//           { path: "/analytics", element: <Suspense fallback={<h1>Loading ..............</h1>}><Analytics /></Suspense> },
//           { path: "/detail", element: <Suspense fallback={<h1>Loading ..............</h1>}><AnalyticsDetail /></Suspense> },
//           { path: "/it", element: <Suspense fallback={<h1>Loading ..............</h1>}><Itsoulation /></Suspense> },
//           { path: "/itdigital", element: <Suspense fallback={<h1>Loading ..............</h1>}><ItDigital /></Suspense> },
//           { path: "/hiring", element: <Suspense fallback={<h1>Loading ..............</h1>}><Hiring /></Suspense> },
//           { path: "/hiring/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><Student /></Suspense> },
//           { path: "/career", element: <Suspense fallback={<h1>Loading ..............</h1>}><Career /></Suspense> },
//           { path: "/opening/:job", element: <Suspense fallback={<h1>Loading ..............</h1>}><CareerDetail /></Suspense> },
//           { path: "/contact", element: <Suspense fallback={<h1>Loading ..............</h1>}><Contact /></Suspense> },
//           { path: "/Blog", element: <Suspense fallback={<h1>Loading ..............</h1>}><Blog /></Suspense> },
//           { path: "/BlogDetail/:title", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogDetail /></Suspense> },
//           { path: "/BlogAll", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogAll /></Suspense> },
//           { path: "/BlogAllCategory/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogAllCategory /></Suspense> },
//           { path: "/lms", element: <Suspense fallback={<h1>Loading ..............</h1>}><Lms /></Suspense> },
//           { path: "/retail", element: <Suspense fallback={<h1>Loading ..............</h1>}><RetailDetail /></Suspense> },
//           { path: "/bank", element: <Suspense fallback={<h1>Loading ..............</h1>}><BankAnalytics /></Suspense> },
//           { path: "/fincial", element: <Suspense fallback={<h1>Loading ..............</h1>}><FincialDetails /></Suspense> },
//           { path: "/cloud", element: <Suspense fallback={<h1>Loading ..............</h1>}><CloudDetails /></Suspense> },
//           { path: "/deta", element: <Suspense fallback={<h1>Loading ..............</h1>}><DetaDetails /></Suspense> },
//           { path: "/Auto", element: <Suspense fallback={<h1>Loading ..............</h1>}><AutoDetails /></Suspense> },
//           { path: "/Telecom", element: <Suspense fallback={<h1>Loading ..............</h1>}><TeleDetails /></Suspense> },
//           { path: "/Insurance", element: <Suspense fallback={<h1>Loading ..............</h1>}><Insurance /></Suspense> },
//           { path: "/Life", element: <Suspense fallback={<h1>Loading ..............</h1>}><Life /></Suspense> },
//           { path: "/Social", element: <Suspense fallback={<h1>Loading ..............</h1>}><Social /></Suspense> },
//         ],
//       },
//       {
//         path: "/login",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><Login /></Suspense>,
//       },
//       {
//         path: "/auth",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><Auth /></Suspense>,
//       },
     
//       {
//         path: "/privacy",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><Privacy /></Suspense>,
//       },
//       {
//         path: "/term",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><Term /></Suspense>,
//       },
//       {
//         path: "/thankyou",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><Thankyou /></Suspense>,
//       },
//       {
//         path: "/enroll",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><EnrollNow /></Suspense>,
//       },


//       {
//         path: "/admin",
//         element: <Suspense fallback={<h1>Loading ..............</h1>}><Login /> </Suspense>
//       },
//       {
//         path: "/Dashboard",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['admin', 'user']}>
//               <Sidebar>
//                 <Dashboard />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
//       // Offer routes
//       {
//         path: "/Add Offer",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Offer Editior']}>
//               <Sidebar>
//                 <AddOffer />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info Offer",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Offer Editior']}>
//               <Sidebar>
//                 <InfoOffer />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Update/:id",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Offer Editior']}>
//               <Sidebar>
//                 <UpdateOffer />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
//       // Batch routes
//       {
//         path: "/Add Batch",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Batch Editior']}>
//               <Sidebar>
//                 <AddBatch />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info Batch",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Batch Editior']}>
//               <Sidebar>
//                 <InfoBatch />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/UpdateBatch/:id",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Batch Editior']}>
//               <Sidebar>
//                 <UpdateBatch />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
//       // Training routes
//       {
//         path: "/HomeTraining",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Training Editior']}>
//               <Sidebar>
//                 <HomeTraining />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Add Training",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Training Editior']}>
//               <Sidebar>
//                 <AddTraining />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/TDetail",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Training Editior']}>
//               <Sidebar>
//                 <TDetails />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info Training",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Training Editior']}>
//               <Sidebar>
//                 <InfoTraining />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/UpdateTDetail/:id",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Training Editior']}>
//               <Sidebar>
//                 <UpdateTraining />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
//       // Career routes
//       {
//         path: "/Add Career",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Career Editior']}>
//               <Sidebar>
//                 <ACareer />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info Career",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Career Editior']}>
//               <Sidebar>
//                 <InfoCareer />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/UpdateCareer/:id",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Career Editior']}>
//               <Sidebar>
//                 <UpdateCareer />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
//       // Blog routes
//       {
//         path: "/HomeBlog",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Blog Editior']}>
//               <Sidebar>
//                 <HomeBlog />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Add Blog",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Blog Editior']}>
//               <Sidebar>
//                 <ABlog />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/BDetail",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Blog Editior']}>
//               <Sidebar>
//                 <BlogDetails />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info Blog",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Blog Editior']}>
//               <Sidebar>
//                 <InfoBlog />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
//       // Hiring routes
//       {
//         path: "/Add Hiring",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Hiring Editior']}>
//               <Sidebar>
//                 <AHiring />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info Hiring",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Hiring Editior']}>
//               <Sidebar>
//                 <InfoHiring />
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
      
      
      
      
//       // Recent News
//       {
//         path: "/Add News",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Recent Editior']}>
//               <Sidebar>
//               <Recent/>
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },
//       {
//         path: "/Info News",
//         element: (
//           <Suspense fallback={<h1>Loading ..............</h1>}>
//             <Auth roles={['Recent Editior']}>
//               <Sidebar>
//               <InfoNews/>
//               </Sidebar>
//             </Auth>
//           </Suspense>
//         )
//       },



//     ]);
  
//     return (
//       <React.StrictMode>
//         <RouterProvider router={router} />
//       </React.StrictMode>
//     );
//   };
  
//   // Render the App component
//   ReactDOM.createRoot(document.getElementById('root')).render(<App />);
  












import React, { Suspense, lazy, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import EnrollNow from './Pages/Training/EnrollNow.js';
import Lms from './Component/LMS/Lms.js';
import Privacy from './Component/Pricavy/Privacy.js';
import Term from './Component/Term/Term.js';
import Error from './Component/Error/Error.js';
import Student from './Pages/Hiring/Student.js';
import Main from './Pages/Landing/Main.js';
const UpdateOffer = lazy(()=> import("./Admin/AdminPages/Offer/UpdateOffer"))
const AddBatch = lazy(()=> import("./Admin/AdminPages/Batch/AddBatch"))
const InfoBatch = lazy(()=> import("./Admin/AdminPages/Batch/InfoBatch"))
const UpdateBatch = lazy(()=> import("./Admin/AdminPages/Batch/UpdateBatch"))
const InfoTraining = lazy(()=> import("./Admin/AdminPages/Training/InfoTraining.js"))
const HomeTraining = lazy(()=> import("./Admin/AdminPages/Training/HomeTraining.js"))
const TDetails = lazy(()=> import("./Admin/AdminPages/Training/TrainingDetails.js"))
const ACareer = lazy(()=> import("./Admin/AdminPages/Career/Career.js"))
const InfoCareer = lazy(()=> import("./Admin/AdminPages/Career/InfoCareer.js"))
const UpdateCareer = lazy(()=> import("./Admin/AdminPages/Career/UpdateCareer.js"))
const UpdateTraining = lazy(()=> import("./Admin/AdminPages/Training/UpdateTraining.js"))
const ABlog = lazy(()=> import("./Admin/AdminPages/Blog/Blog.js"))
const HomeBlog = lazy(()=> import("./Admin/AdminPages/Blog/HomeBlog.js"))
const BlogDetails = lazy(()=> import("./Admin/AdminPages/Blog/BlogDetails.js"))
const InfoBlog = lazy(()=> import("./Admin/AdminPages/Blog/InfoBlog.js"))
const AHiring = lazy(()=> import("./Admin/AdminPages/Hiring/Hiring.js"))
const InfoHiring = lazy(()=> import("./Admin/AdminPages/Hiring/InfoHiring.js"))
const ItDevelopment = lazy(()=> import("./Pages/ITSoulation/ItDevelopment.js"))
const Recent = lazy(()=> import("./Admin/AdminPages/RecentNews/Recent.js"))
const InfoNews = lazy(()=> import("./Admin/AdminPages/RecentNews/InfoNews.js"))
const RetailDetail = lazy(()=> import('./Pages/Analytics/RetailDetails.js'))
const ItDigital = lazy(()=> import("./Pages/ITSoulation/Itdigital.js"))


const BankAnalytics = lazy(()=> import("./Pages/Analytics/BankAnalytics.js"))
const FincialDetails = lazy(()=> import("./Pages/Analytics/FincialDetails.js"))
const CloudDetails = lazy(()=> import("./Pages/Analytics/CloudDetails.js"))
const DetaDetails = lazy(()=> import("./Pages/Analytics/DetaDetails.js"))
const AutoDetails = lazy(()=> import("./Pages/Analytics/AutoDetails.js"))
const TeleDetails = lazy(()=> import("./Pages/Analytics/TeleDetails.js"))
const Insurance = lazy(()=> import("./Pages/Analytics/InsuranceDetail.js"))
const Life = lazy(()=> import("./Pages/Analytics/LifeDetail.js"))
const Social = lazy(()=> import("./Pages/Analytics/SocialDetail.js"))


const Layout = lazy(() => import('./Pages/Layout'));
const Home = lazy(() => import('./Pages/Home/Home'));
const About = lazy(() => import('./Pages/About/About'));
const Training = lazy(() => import('./Pages/Training/Training'));
const Consulting = lazy(() => import('./Pages/Consulting/Consulting'));
const ConsultingDetails = lazy(() => import('./Pages/Consulting/ConsultingDetails'));
const TrainingDetail = lazy(() => import('./Pages/Training/TrainingDetail'));
const Analytics = lazy(() => import('./Pages/Analytics/Analytics'));
const AnalyticsDetail = lazy(() => import('./Pages/Analytics/AnalyticsDetail'));
const Itsoulation = lazy(() => import('./Pages/ITSoulation/Itsoulation'));
const Hiring = lazy(() => import('./Pages/Hiring/Hiring'));
const Career = lazy(() => import('./Pages/Career/Career'));
const CareerDetail = lazy(() => import('./Pages/Career/CareerDetail'));
const Contact = lazy(() => import('./Pages/Contactus/contact'));
const Blog = lazy(() => import('./Pages/Blog/Blog'));
const BlogDetail = lazy(() => import('./Pages/Blog/BlogDetail'));
const BlogAll = lazy(() => import('./Pages/Blog/BlogAll'));
const BlogAllCategory = lazy(() => import('./Pages/Blog/BlogAllCategory'))



const Login = lazy(() => import('./Component/Login/login'));
const Auth = lazy(() => import('./Component/Login/Auth'));
const Sidebar = lazy(() => import('./Admin/AdminComponent/Sidebar'));
const Dashboard = lazy(() => import('./Admin/Dashboard'));
const AddOffer = lazy(() => import('./Admin/AdminPages/Offer/AddOffer'));
const InfoOffer = lazy(() => import('./Admin/AdminPages/Offer/InfoOffer'));
const AddTraining = lazy(() => import('./Admin/AdminPages/Training/Training.js'))
const Thankyou = lazy(() => import('./Component/Thankyou/Thankyou.js'))

const LMSComing = lazy(()=> import('./Component/LMS/LMSComing.js'))
const Landing = lazy(()=> import('./Affiliate/Landing.js'))

// Functional component for managing state and effects
const App = () => {
    const [showLMSComing, setShowLMSComing] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowLMSComing(false);
      }, 90000); // Show LMSComing for 5 seconds
  
      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, []);
  
    // Define a generic error component if not already defined
    const Error = () => <div>Error: Page not found!</div>;
  
    // Router configuration
    const router = createBrowserRouter([
      {
        path: "/",
        element: <Layout />,
        errorElement: <Error />,
        children: [
          {
            index: true,
            element: (
              // <Suspense fallback={<h1>Loading ..............</h1>}>
              //   {showLMSComing ? <LMSComing /> : <Home />}
              // </Suspense>
              <Suspense fallback = {<h1>Loading ........</h1>}><Home/></Suspense>
            ),
          },

 

          { path: "/about", element: <Suspense fallback={<h1>Loading ..............</h1>}><About /></Suspense> },
          { path: "/training", element: <Suspense fallback={<h1>Loading ..............</h1>}><Training /></Suspense> },
          { path: "/consulting", element: <Suspense fallback={<h1>Loading ..............</h1>}><Consulting /></Suspense> },
          { path: "/consultingdetails", element: <Suspense fallback={<h1>Loading ..............</h1>}><ConsultingDetails /></Suspense> },
          { path: "/courseDetail/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><TrainingDetail /></Suspense> },
          { path: "/analytics", element: <Suspense fallback={<h1>Loading ..............</h1>}><Analytics /></Suspense> },
          { path: "/detail", element: <Suspense fallback={<h1>Loading ..............</h1>}><AnalyticsDetail /></Suspense> },
          { path: "/it", element: <Suspense fallback={<h1>Loading ..............</h1>}><Itsoulation /></Suspense> },
          { path: "/itdigital", element: <Suspense fallback={<h1>Loading ..............</h1>}><ItDigital /></Suspense> },
          { path: "/hiring", element: <Suspense fallback={<h1>Loading ..............</h1>}><Hiring /></Suspense> },
          { path: "/hiring/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><Student /></Suspense> },
          { path: "/career", element: <Suspense fallback={<h1>Loading ..............</h1>}><Career /></Suspense> },
          { path: "/opening/:job", element: <Suspense fallback={<h1>Loading ..............</h1>}><CareerDetail /></Suspense> },
          { path: "/contact", element: <Suspense fallback={<h1>Loading ..............</h1>}><Contact /></Suspense> },
          { path: "/Blog", element: <Suspense fallback={<h1>Loading ..............</h1>}><Blog /></Suspense> },
          { path: "/BlogDetail/:title", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogDetail /></Suspense> },
          { path: "/BlogAll", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogAll /></Suspense> },
          { path: "/BlogAllCategory/:name", element: <Suspense fallback={<h1>Loading ..............</h1>}><BlogAllCategory /></Suspense> },
          { path: "/lms", element: <Suspense fallback={<h1>Loading ..............</h1>}><Lms /></Suspense> },
          { path: "/retail", element: <Suspense fallback={<h1>Loading ..............</h1>}><RetailDetail /></Suspense> },
          { path: "/bank", element: <Suspense fallback={<h1>Loading ..............</h1>}><BankAnalytics /></Suspense> },
          { path: "/fincial", element: <Suspense fallback={<h1>Loading ..............</h1>}><FincialDetails /></Suspense> },
          { path: "/cloud", element: <Suspense fallback={<h1>Loading ..............</h1>}><CloudDetails /></Suspense> },
          { path: "/deta", element: <Suspense fallback={<h1>Loading ..............</h1>}><DetaDetails /></Suspense> },
          { path: "/Auto", element: <Suspense fallback={<h1>Loading ..............</h1>}><AutoDetails /></Suspense> },
          { path: "/Telecom", element: <Suspense fallback={<h1>Loading ..............</h1>}><TeleDetails /></Suspense> },
          { path: "/Insurance", element: <Suspense fallback={<h1>Loading ..............</h1>}><Insurance /></Suspense> },
          { path: "/Life", element: <Suspense fallback={<h1>Loading ..............</h1>}><Life /></Suspense> },
          { path: "/Social", element: <Suspense fallback={<h1>Loading ..............</h1>}><Social /></Suspense> },
       

          { path: "/Thankyou", element: <Suspense fallback={<h1>Loading ..............</h1>}><Thankyou/> </Suspense> },
          { path: "/privacy", element: <Suspense fallback={<h1>Loading ..............</h1>}><Privacy/> </Suspense> },
          { path: "/term", element: <Suspense fallback={<h1>Loading ..............</h1>}><Term/> </Suspense> },
          
         
          { path: "/Enroll/:batchname", element: <Suspense fallback={<h1>Loading ..............</h1>}><EnrollNow/> </Suspense> },
        ],
      },

// landing page

      {
        path: "/landing",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Main/></Suspense>,
      },

      {
        path: "/partner-program",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Landing/> </Suspense>,
      },


      // {
      //   index: true,
      //   element: (
      //     <Suspense fallback={<h1>Loading ..............</h1>}>
      //       {showLMSComing ? <LMSComing /> : <Home />}
      //     </Suspense>
      //   ),
      // },
      {
        path: "/login",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Login /></Suspense>,
      },
      {
        path: "/auth",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Auth /></Suspense>,
      },
     
      {
        path: "/privacy",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Privacy /></Suspense>,
      },
      {
        path: "/term",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Term /></Suspense>,
      },
      {
        path: "/thankyou",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Thankyou /></Suspense>,
      },
      {
        path: "/enroll",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><EnrollNow /></Suspense>,
      },


      {
        path: "/admin",
        element: <Suspense fallback={<h1>Loading ..............</h1>}><Login /> </Suspense>
      },
      {
        path: "/Dashboard",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['admin', 'user']}>
              <Sidebar>
                <Dashboard />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      // Offer routes
      {
        path: "/Add Offer",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Offer Editior']}>
              <Sidebar>
                <AddOffer />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info Offer",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Offer Editior']}>
              <Sidebar>
                <InfoOffer />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Update/:id",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Offer Editior']}>
              <Sidebar>
                <UpdateOffer />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      // Batch routes
      {
        path: "/Add Batch",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Batch Editior']}>
              <Sidebar>
                <AddBatch />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info Batch",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Batch Editior']}>
              <Sidebar>
                <InfoBatch />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/UpdateBatch/:id",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Batch Editior']}>
              <Sidebar>
                <UpdateBatch />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      // Training routes
      {
        path: "/HomeTraining",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Training Editior']}>
              <Sidebar>
                <HomeTraining />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Add Training",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Training Editior']}>
              <Sidebar>
                <AddTraining />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/TDetail",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Training Editior']}>
              <Sidebar>
                <TDetails />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info Training",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Training Editior']}>
              <Sidebar>
                <InfoTraining />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/UpdateTDetail/:id",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Training Editior']}>
              <Sidebar>
                <UpdateTraining />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      // Career routes
      {
        path: "/Add Career",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Career Editior']}>
              <Sidebar>
                <ACareer />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info Career",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Career Editior']}>
              <Sidebar>
                <InfoCareer />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/UpdateCareer/:id",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Career Editior']}>
              <Sidebar>
                <UpdateCareer />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      // Blog routes
      {
        path: "/HomeBlog",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Blog Editior']}>
              <Sidebar>
                <HomeBlog />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Add Blog",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Blog Editior']}>
              <Sidebar>
                <ABlog />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/BDetail",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Blog Editior']}>
              <Sidebar>
                <BlogDetails />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info Blog",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Blog Editior']}>
              <Sidebar>
                <InfoBlog />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      // Hiring routes
      {
        path: "/Add Hiring",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Hiring Editior']}>
              <Sidebar>
                <AHiring />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info Hiring",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Hiring Editior']}>
              <Sidebar>
                <InfoHiring />
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      
      
      
      
      // Recent News
      {
        path: "/Add News",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Recent Editior']}>
              <Sidebar>
              <Recent/>
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },
      {
        path: "/Info News",
        element: (
          <Suspense fallback={<h1>Loading ..............</h1>}>
            <Auth roles={['Recent Editior']}>
              <Sidebar>
              <InfoNews/>
              </Sidebar>
            </Auth>
          </Suspense>
        )
      },



    ]);
  
    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  };
  
  // Render the App component
  ReactDOM.createRoot(document.getElementById('root')).render(<App />);
  